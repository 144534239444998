import { Fragment, useContext } from "react";
import Footer from "../footer";
import MainNav from "./mainNav";
import Opps from "../../assets/opps.png";
import Style from './404.module.css';
import Language from '../../store/language';


import { Link, useLocation } from "react-router-dom";
const Error404 = () =>{
    const location = useLocation()
    const langCtx = useContext(Language);

    return(
        <Fragment>
            <Footer></Footer>
            <MainNav></MainNav>
            <div style={{height:'77vh' , display:'flex' , justifyContent:'center' , alignItems:'center'}} className={Style.errorDiv}>
                <div>
                    <div className={Style.imageDiv}>
                        <img className={Style.oppsImg} src={Opps}></img>
                    </div>
                    <div className={Style.errorText}>
                        <h1>404 ERROR!</h1>
                        <h3>
                            {langCtx.language === 'english' ?'Page not found!':langCtx.language === 'arabic' ?'لم يتم العثور على الصفحة التي تريدها':langCtx.language === 'persian' ?'صفحه مورد نظر شما یافت نشد':null}                    
                        </h3>
                    </div>
                    <div className={Style.backToHome}>
                        <Link to={`/${location.pathname.split('/')[1]}/`}><button style={{padding:'6px 13px 6px 13px'}}>{langCtx.language === 'english' ?'Home':langCtx.language === 'arabic' ?'الصفحة الرئيسية':langCtx.language === 'persian' ?'خانه':null}</button></Link>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default Error404;