import { Fragment , useEffect , useContext } from "react";
import Style from './shiping.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Lottie from "lottie-react";
import MainAnimation from "../assets/shipingMainVector.json";
import Arrow from "../assets/arrow.json";
import {Pagination,Navbar,Row,  Nav ,NavDropdown , Container ,Form ,FormControl ,Button, Col} from 'react-bootstrap';
import Footer from "./footer";
import MainNav from "./tools/mainNav";
import Gallery from "../assets/gallery.png";
import Oprator from "../assets/opratorIcon.png";
import Package from "../assets/package.png";
import ShipingVector from "../assets/shiping.png";
import ActivePage from "../store/activePage";
import {Helmet} from "react-helmet";
import Language from "../store/language";

import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";

const Shiping = () =>{
    const langCtx = useContext(Language);

    const activePage = useContext(ActivePage);

    const location = useLocation();
    useEffect(()=>{
        if(location.pathname.split('/')[1]=== 'pr'){

            Cookies.set('currentLang','persian' , {sameSite: 'strict', secure: false , expires:8});
            langCtx.activeLangFn('persian');
        }else if(location.pathname.split('/')[1] === 'en'){
            Cookies.set('currentLang','english' , {sameSite: 'strict', secure: false , expires:8});
            langCtx.activeLangFn('english');
        }else if(location.pathname.split('/')[1] === 'ar'){
            Cookies.set('currentLang','arabic' , {sameSite: 'strict', secure: false , expires:8});
            langCtx.activeLangFn('arabic');
        }
    },[])
    useEffect(() => {
        activePage.activePageFn('shipping');
    }, []);
    return(
        <Fragment>
            
            {langCtx.language==='persian'?
                <Helmet>
                    <title>حمل و نقل</title>
                    <meta name="description" content="حمل و نقل در لازولیت ماربل به چه صورت انجام می گیرد؟" />
                </Helmet>
                :
                langCtx.language==='english'?
                <Helmet>
                    <title>حمل و نقل</title>
                    <meta name="description" content="حمل و نقل در لازولیت ماربل به چه صورت انجام می گیرد؟" />
                </Helmet>
                :
                langCtx.language==='arabic'?
                <Helmet>
                    <title>حمل و نقل</title>
                    <meta name="description" content='حمل و نقل در لازولیت ماربل به چه صورت انجام می گیرد؟' />
                </Helmet>
                :null
            } 
        {/* portals */}
        <Footer></Footer> 
        <MainNav></MainNav>
            {langCtx.language === 'english' ?
                <Row>
                    <div style={{padding:'0px'}} className={Style.divContainer}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className={Style.introDiv}>
                                <div className={Style.blueSection}>
                                </div>
                                <div className={Style.animatedSectionDiv}>
                                    <Lottie loop={true} animationData={MainAnimation} />
                                </div>
                                <h1>how is transportation being done in Lazulite Marble company?</h1>
                                <div className={Style.arrowDiv}>
                                    <Lottie loop={true} animationData={Arrow} />
                                </div>
                            </div>
                            <div className={Style.blueLine}></div>
                        </Col>
                        <div dir="ltr" className={Style.card1}>
                            <Row className={Style.rowStyle} style={{ margin:'0px auto 0px auto'}}>
                                <div className={Style.ovDiv}>
                                    <div className={Style.wapper}>
                                        <div style={{margin:'0px auto 0px auto' , textAlign:'center'}} className={Style.circle}>
                                            <img src={Gallery}></img>
                                        </div>
                                    </div>
                                    <div style={{padding:'0px 10px 0px 10px'}} className={Style.cardInfo}>
                                        <div style={{width:'100%' , textAlign:'left'}}>
                                            <h2>Product selection</h2>
                                            <p dir="ltr">
                                            First, choose your desired stone through the updated gallery of Lazulite Marble slabs. Meanwhile, you can contact our operators for consultation.                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                        <div dir="rtl" style={{backgroundColor:'#C9E5F3'}} className={Style.card1}>
                            <Row className={Style.rowStyle} style={{ margin:'0px auto 0px auto'}}>
                                <div className={Style.ovDiv}>
                                    <div className={Style.wapper}>
                                        <div style={{margin:'0px auto 0px auto' , textAlign:'center'}} className={Style.circle}>
                                            <img src={Oprator}></img>
                                        </div>
                                    </div>
                                    <div style={{padding:'0px 10px 0px 10px'}} className={Style.cardInfo}  dir="rtl">
                                        <div style={{width:'100%' , textAlign:'left'}}>   
                                            <h2>Consultation and purchase</h2>
                                            <p dir="ltr">                                       
                                            Please share your selection with one of our operators and wait to receive your purchase invoice.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                        <div dir="ltr" className={Style.card1}>
                            <Row className={Style.rowStyle} style={{ margin:'0px auto 0px auto'}}>
                                <div className={Style.ovDiv}>
                                    <div className={Style.wapper}>
                                        <div style={{margin:'0px auto 0px auto' , textAlign:'center'}} className={Style.circle}>
                                            <img src={Package}></img>
                                        </div>
                                    </div>
                                    <div style={{padding:'0px 10px 0px 10px'}} className={Style.cardInfo}>
                                        <div style={{width:'100%' , textAlign:'left'}}>
                                            <h2>packing</h2>
                                            <p dir="ltr">
                                            At this stage, your selected stones will be placed on wooden pallets. The wooden pallets help prevent any damage to the stones.                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </Row>
                        </div>
                        <div dir="rtl" style={{backgroundColor:'#C9E5F3'}} className={Style.card1}>
                            <Row className={Style.rowStyle} style={{ margin:'0px auto 0px auto'}}>
                                <div className={Style.ovDiv}>
                                    <div className={Style.wapper}>
                                        <div style={{margin:'0px auto 0px auto' , textAlign:'center'}} className={Style.circle}>
                                            <img src={ShipingVector}></img>
                                        </div>
                                    </div>
                                    <div  style={{padding:'0px 10px 0px 10px'}} className={Style.cardInfo}  dir="rtl">
                                        <div style={{width:'100%' , textAlign:'left'}}>   
                                            <h2>Shipment</h2>
                                            <p dir="ltr">                                       
                                            Your shipment will be sent to you using one of several available methods.                               </p>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>

                    </div>
                </Row>
            :langCtx.language === 'persian'?
                <Row>
                    <div style={{padding:'0px'}} className={Style.divContainer}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className={Style.introDiv}>
                                <div className={Style.blueSection}>
                                </div>
                                <div className={Style.animatedSectionDiv}>
                                    <Lottie loop={true} animationData={MainAnimation} />
                                </div>
                                <h1>حمل و نقل در لازولیت ماربل به چه صورت انجام می گیرد؟ </h1>
                                <div className={Style.arrowDiv}>
                                    <Lottie loop={true} animationData={Arrow} />
                                </div>
                            </div>
                            <div className={Style.blueLine}></div>
                        </Col>
                        <div dir="ltr" className={Style.card1}>
                            <Row className={Style.rowStyle} style={{ margin:'0px auto 0px auto'}}>
                                <div className={Style.ovDiv}>
                                    <div className={Style.wapper}>
                                        <div style={{margin:'0px auto 0px auto' , textAlign:'center'}} className={Style.circle}>
                                            <img src={Gallery}></img>
                                        </div>
                                    </div>
                                    <div style={{padding:'0px 10px 0px 10px'}} className={Style.cardInfo}>
                                        <div style={{width:'100%' , textAlign:'right'}}>
                                            <h2>انتخاب محصول</h2>
                                            <p dir="rtl">
                                            ابتدا سنگ مورد نظر خود را از طریق گالری اسلب های بروز لازولیت ماربل انتخاب کنید، در عین حال می توانید برای مشاوره با اپراتور های ما در تماس باشید.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                        <div dir="rtl" style={{backgroundColor:'#C9E5F3'}} className={Style.card1}>
                            <Row className={Style.rowStyle} style={{ margin:'0px auto 0px auto'}}>
                                <div className={Style.ovDiv}>
                                    <div className={Style.wapper}>
                                        <div style={{margin:'0px auto 0px auto' , textAlign:'center'}} className={Style.circle}>
                                            <img src={Oprator}></img>
                                        </div>
                                    </div>
                                    <div style={{padding:'0px 10px 0px 10px'}} className={Style.cardInfo}  dir="rtl">
                                        <div style={{width:'100%' , textAlign:'right'}}>   
                                            <h2>مشاوره و خرید</h2>
                                            <p>                                       
                                            انتخاب خود را با یکی از اپراتور های ما در میان بگذارید
    و منتظر دریافت فاکتور خرید خود باشید.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                        <div dir="ltr" className={Style.card1}>
                            <Row className={Style.rowStyle} style={{ margin:'0px auto 0px auto'}}>
                                <div className={Style.ovDiv}>
                                    <div className={Style.wapper}>
                                        <div style={{margin:'0px auto 0px auto' , textAlign:'center'}} className={Style.circle}>
                                            <img src={Package}></img>
                                        </div>
                                    </div>
                                    <div style={{padding:'0px 10px 0px 10px'}} className={Style.cardInfo}>
                                        <div style={{width:'100%' , textAlign:'right'}}>
                                            <h2>بسته بندی</h2>
                                            <p dir="rtl">
                                                در این مرحله سنگ های انتخابی شما در پالت های چوبی قرار می گیرد؛پالت های چـــوبی از آســیب سنگ ها جلوگیری می کند.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </Row>
                        </div>
                        <div dir="rtl" style={{backgroundColor:'#C9E5F3'}} className={Style.card1}>
                            <Row className={Style.rowStyle} style={{ margin:'0px auto 0px auto'}}>
                                <div className={Style.ovDiv}>
                                    <div className={Style.wapper}>
                                        <div style={{margin:'0px auto 0px auto' , textAlign:'center'}} className={Style.circle}>
                                            <img src={ShipingVector}></img>
                                        </div>
                                    </div>
                                    <div  style={{padding:'0px 10px 0px 10px'}} className={Style.cardInfo}  dir="rtl">
                                        <div style={{width:'100%' , textAlign:'right'}}>   
                                            <h2>ارسال</h2>
                                            <p>                                       
                                            بار شما با یکی از چند روش انتخابی برای شما ارسال 
    می شود.                                        </p>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>

                    </div>
                </Row>
            :langCtx.language==='arabic'?
                <Row>
                    <div style={{padding:'0px'}} className={Style.divContainer}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className={Style.introDiv}>
                                <div className={Style.blueSection}>
                                </div>
                                <div className={Style.animatedSectionDiv}>
                                    <Lottie loop={true} animationData={MainAnimation} />
                                </div>
                                <h1>كيفية نقل وشحن الرخام في شركة لازولايت ماربل؟</h1>
                                <div className={Style.arrowDiv}>
                                    <Lottie loop={true} animationData={Arrow} />
                                </div>
                            </div>
                            <div className={Style.blueLine}></div>
                        </Col>
                        <div dir="ltr" className={Style.card1}>
                            <Row className={Style.rowStyle} style={{ margin:'0px auto 0px auto'}}>
                                <div className={Style.ovDiv}>
                                    <div className={Style.wapper}>
                                        <div style={{margin:'0px auto 0px auto' , textAlign:'center'}} className={Style.circle}>
                                            <img src={Gallery}></img>
                                        </div>
                                    </div>
                                    <div style={{padding:'0px 10px 0px 10px'}} className={Style.cardInfo}>
                                        <div style={{width:'100%' , textAlign:'right'}}>
                                            <h2>انتخاب المنتج</h2>
                                            <p dir="rtl">
                                            ابتدا اختيار الحجر المرغوب الخاص بك من معرض أحدث المنتجات الخاص بشركة لازوليت ماربل وفي نفس الوقت يمكنك التواصل مع مشغلينا للحصول على استشارة.                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                        <div dir="rtl" style={{backgroundColor:'#C9E5F3'}} className={Style.card1}>
                            <Row className={Style.rowStyle} style={{ margin:'0px auto 0px auto'}}>
                                <div className={Style.ovDiv}>
                                    <div className={Style.wapper}>
                                        <div style={{margin:'0px auto 0px auto' , textAlign:'center'}} className={Style.circle}>
                                            <img src={Oprator}></img>
                                        </div>
                                    </div>
                                    <div style={{padding:'0px 10px 0px 10px'}} className={Style.cardInfo}  dir="rtl">
                                        <div style={{width:'100%' , textAlign:'right'}}>   
                                            <h2>الاستشارة والشراء</h2>
                                            <p>                                       
                                            قم بمشاركة اختيارك مع أحد مشغلينا وانتظر استلام فاتورة الشراء الخاصة بك.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                        <div dir="ltr" className={Style.card1}>
                            <Row className={Style.rowStyle} style={{ margin:'0px auto 0px auto'}}>
                                <div className={Style.ovDiv}>
                                    <div className={Style.wapper}>
                                        <div style={{margin:'0px auto 0px auto' , textAlign:'center'}} className={Style.circle}>
                                            <img src={Package}></img>
                                        </div>
                                    </div>
                                    <div style={{padding:'0px 10px 0px 10px'}} className={Style.cardInfo}>
                                        <div style={{width:'100%' , textAlign:'right'}}>
                                            <h2>التعبئة</h2>
                                            <p dir="rtl">
                                            في هذه المرحلة، يتم وضع الأحجار التي اخترتها على منصات خشبية؛ وتحمي هذه المنصات الخشبية الأحجار من التلف.                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </Row>
                        </div>
                        <div dir="rtl" style={{backgroundColor:'#C9E5F3'}} className={Style.card1}>
                            <Row className={Style.rowStyle} style={{ margin:'0px auto 0px auto'}}>
                                <div className={Style.ovDiv}>
                                    <div className={Style.wapper}>
                                        <div style={{margin:'0px auto 0px auto' , textAlign:'center'}} className={Style.circle}>
                                            <img src={ShipingVector}></img>
                                        </div>
                                    </div>
                                    <div  style={{padding:'0px 10px 0px 10px'}} className={Style.cardInfo}  dir="rtl">
                                        <div style={{width:'100%' , textAlign:'right'}}>   
                                            <h2>الشحن</h2>
                                            <p>                                       
                                            يتم إرسال شحنتك باستخدام أحد الطرق المختارة لك.                             </p>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>

                    </div>
                </Row>
            :null}
        </Fragment>
    )
}
export default Shiping;